.is-cms-content-page .ContentWrapper {
    padding: 0;
    max-width: 100%;
}

.cms-placements {
    display: block;
    position: relative;
    max-width: 1920px;

    [class*="Carousel_slides_"] {
        padding-bottom: var(--spacing-6);

        @media (min-width: 768px) {
            padding-bottom: var(--spacing-10);
        }
    }
}

body {
    box-sizing: border-box;
}

#__next,
.cms-placements {
    #content-x div {
        &[data-testid="container"],
        &[data-testid="club-finder-block"],
        &[data-testid="tabbed-quick-links-block"],
        &[data-testid="brand-block-container"],
        &[data-testid="cards-block"] {
            margin: auto;
        }
    }

    h1,
    h1 span,
    h2,
    h3,
    h4,
    h5,
    p,
    label,
    input,
    optgroup,
    select,
    textarea {
        color: var(--color);
    }

    div[data-testid="markdown-block"] img {
        max-width: 100%;
    }

    ul,
    ul li {
        list-style: initial;
    }

    ol,
    ol li {
        list-style: decimal;
    }

    ul,
    ol {
        padding-left: var(--spacing-4, 16px);
    }

    button {
        border-radius: var(--shape-border-radius);

        &[data-testid] {
            &[data-testid="tabbed-quick-links-block-button"]:hover {
                color: unset;
                background-color: unset;
            }

            &[data-testid="accordion-group"] {
                margin: 0 12px;
                border-top: 1px solid var(--grey-300);

                @media (min-width: 1022px) {
                    margin: 0;
                }
            }

            &[data-testid="accordion-summary"] {
                border-color: var(--grey-300);
                background-color: var(--common-white) !important;

                &:hover {
                    color: unset;
                    background-color: unset;
                }
            }

            &[data-testid="accordion-summary"] {
                &:hover {
                    color: var(--primary-main) !important;
                }
            }
        }
    }

    [class*="Carousel_counteractMargins"] {
        width: calc(100% + var(--spacing-8));
        margin-left: var(--negative-spacing-4);
        margin-right: var(--negative-spacing-4);

        @media (min-width: 768px) {
            width: calc(100% + var(--spacing-12));
            margin-left: var(--negative-spacing-6);
            margin-right: var(--negative-spacing-6);
        }

        @media (min-width: 992px) {
            width: calc(100% + var(--spacing-24));
            margin-left: var(--negative-spacing-12);
            margin-right: var(--negative-spacing-12);
        }

        @media (min-width: 1440px) {
            width: calc(100% + var(--spacing-48));
            margin-left: var(--negative-spacing-24);
            margin-right: var(--negative-spacing-24);
        }
    }

    [class*="Carousel_slides_"] {
        @supports (not (selector(::-webkit-scrollbar))) {
            scrollbar-color: var(--primary-main) var(--common-white);
            scrollbar-width: thin;
        }
    }

    [class*="ContentBlocks_hasSidebar"] [class*="BrandListBlock"] [class*="Carousel_slides"] {
        --scrollbar-margin: calc(var(--container-width) * 0.38 - 80px);
    }

    [class*="ReadMore_button"],
    [class*="ReadMore_button"]:hover {
        background-color: transparent !important;
        color: var(--primary-main) !important;
    }

    [class*="Button"] {
        border-radius: 20px;
    }

    [class*="Button_primary"] {
        border-color: var(--primary-main);
        background: var(--primary-main);
        color: var(--common-white);
    }

    [class*="Button_primary"]:hover {
        border-color: var(--primary-main);
        background: var(--primary-main);
        color: var(--secondary-main);
    }

    [class*="Button_black"] {
        border-color: var(--common-black);
        background: var(--common-black);
        color: var(--common-white);
    }

    [class*="Button_black"]:hover {
        border-color: var(--common-black);
        background: var(--common-black);
        color: var(--secondary-main);
    }

    [class*="Button_secondary"] {
        border-color: var(--common-black);
        background: var(--common-white);
        color: var(--common-black);
        font-weight: 700 !important;
    }

    [class*="Button_secondary"]:hover {
        border-color: var(--primary-main);
        background: var(--common-white);
        color: var(--common-black);
    }

    [class*="HeroBlock_overlaid"] h2,
    [class*="HeroBlock_overlaid"] p {
        color: var(--common-white);
    }

    p[class*="Typography_subheading"],
    h2[class*="Typography_subheading"] {
        text-transform: none;
        font-weight: 700;
        color: var(--common-black);
    }

    [class*="Typography_subheading1"] {
        font-size: 18px;
    }

    [class*="Typography_subheading2"] {
        font-size: 16px;
    }

    p {
        font-size: 14px;
    }

    h2 {
        font-size: 28px;
    }

    [class*="MarkdownBlock_center"] {
        margin: 0 auto;
    }

    [class*="Button_sale"] {
        color: var(--red-main);
        background-color: var(--common-white);
        border-color: var(--red-main);
    }

    [class*="Button_sale"]:hover {
        color: var(--common-white);
        background-color: var(--red-main);
    }

    [class*="ReadMore_button"]:hover {
        background-color: var(--primary-main);
    }
}
