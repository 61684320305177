@font-face {
    font-family: sora;
    src: url("/Fonts/Sora-Regular.woff2") format("woff2");
}

@font-face {
    font-family: sora;
    src: url("/Fonts/Sora-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: sora;
    src: url("/Fonts/Sora-ExtraBold.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
